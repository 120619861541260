
.status-filter{
  text-transform: capitalize;
}
.business-access .table{
  td{
    padding-top: 2px;
    padding-bottom: 2px;
    input[type="number"]{}
  }
}
